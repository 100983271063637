import React, { useEffect, useMemo } from 'react';
import { cloneDeep } from 'lodash';
import { PositionNumberTag } from '../../../components/PositionNumberTag';
import { numberPosition } from '../../../../utils';
import { cards } from './data/cards';
// import { LocalStorageKeys, setLocalStorageValue } from '../../../../utils/local-storage';

const STARTING_AMOUNT = 1000;
const OWNER = 'owner';
const OTHERS = 'others';

const getBidAmount = (player, auctionItem, item) => {
    if (!auctionItem) return 0;
    // if the cost per dice is the same for all players, it's the product of the player's bid and the cost per dice
    const bidAmountIsSame = !!auctionItem.costPerDice?.['all'];
    if (bidAmountIsSame) return auctionItem.costPerDice['all'] * item.bids[player];

    // if there is only one dice bid option, it's the value of the player's order bid
    const hasMultipleDiceBidOptions = auctionItem.diceBidOptions.length > 1;
    const playerPosition = item.positions.findIndex(position => position === player) + 1;
    if (!hasMultipleDiceBidOptions) return auctionItem.costPerDice[playerPosition] ?? 0;

    // if the player is not in the positions array, return 0
    const includesPlayer = item.positions.includes(player);
    if (!includesPlayer) return 0;

    // calculate the cost per dice and the position's cost
    return Number(item.bids[player] ?? 1) * (auctionItem.costPerDice[playerPosition] ?? 1) ?? 0;
}

const getDefaultEmptyValue = (players) => ({
    itemId: '',
    positions: [],
    bids: cloneDeep(players).reduce((acc, player) => ({ ...acc, [player]: '' }), {}),
    scores: cloneDeep(players).reduce((acc, player) => ({ ...acc, [player]: 0 }), {}),
    winner: '',
});

export const ScoreSheet = ({ room, setRoom, setSelectedTab }) => {
    const { players = [], items = [] } = room;

    useEffect(() => {
        const playersCopy = cloneDeep(players);
        const itemsCopy = cloneDeep(items).map(item => {
            // add bids for missing players
            const bidKeys = Object.keys(item.bids);
            const missingPlayersInBid = playersCopy.filter(player => !bidKeys.includes(player));
            const newBids = { ...item.bids };
            missingPlayersInBid.forEach(player => {
                newBids[player] = '';
            });
            item.bids = newBids;

            // add scores for missing players
            const scoreKeys = Object.keys(item.scores);
            const missingPlayersInScore = playersCopy.filter(player => !scoreKeys.includes(player));
            const newScores = { ...item.scores };
            missingPlayersInScore.forEach(player => {
                newScores[player] = 0;
            });
            item.scores = newScores;

            return item;
        });

        setRoom({ ...room, items: itemsCopy });
    }, [players.join(',')]);

    const defaultEmptyValue = getDefaultEmptyValue(players);

    const setItemInLocalStorage = (newItems) => {
        setRoom({ ...room, items: newItems });
    }

    const dependencyCheck = items.map(item =>
        item.itemId + JSON.stringify(item.positions) + JSON.stringify(item.bids) + JSON.stringify(item.scores) + JSON.stringify(item.rewards) + item.winner
    ).join(',') + players.join(',');

    const totalScores = useMemo(() => {
        return [{
            scores: [...players].reduce((acc, player) => ({ ...acc, [player]: STARTING_AMOUNT }), {}),
        }, ...items].reduce((acc, item) => {
            players.forEach(player => {
                const auctionItem = cards.find(card => card.id === Number(item.itemId));
                const bidAmount = getBidAmount(player, auctionItem, item);
                const score = item.scores?.[player] ?? 0;
                const total = score - bidAmount;
                acc[player] = (acc[player] ?? 0) + total;
            });
            return acc;
        }, {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dependencyCheck]);

    const totalScoresArray = Object.values(totalScores);
    const sortedPositions = [...new Set([...totalScoresArray])];

    const cardIndexedByName = cards.reduce((acc, card) => ({
        ...acc,
        [card.id]: card,
    }), {});

    return (
        <div className="score-sheet-container tab-section-container">
            <div className="score-sheet-header">
                <div className="score-sheet-summary">
                    <div className="score-sheet-summary-header">
                        <strong>Summary:</strong>
                    </div>

                    <div className="score-sheet-summary-table-heading">
                        <span>Position</span>
                        <span>Points</span>
                    </div>

                    {sortedPositions.sort((a, b) => b - a).map((position, positionIdx) => {
                        const indices = [];
                        totalScoresArray.filter((num, index) => {
                            if (num === position) {
                                indices.push(index);
                            }
                            return num === position
                        });

                        return (
                            <div key={position} className="score-sheet-summary-row">
                                <div className="score-sheet-left-side">
                                    <PositionNumberTag position={positionIdx + 1} />
                                    <span className="score-sheet-summary-players">{indices.map(index => players[index]).join(', ')}</span>
                                </div>
                                <span className={`score-sheet-summary-total position-number-tag ${numberPosition(positionIdx + 1)}`}>{position}</span>
                            </div>
                        )
                    })}
                </div>

                <button
                    className="default-btn"
                    onClick={() => {
                        setRoom({ ...room, scores: [] });
                        setSelectedTab('details');
                        setTimeout(() => {
                            setSelectedTab('score');
                        }, 0);
                    }}
                >
                    Reset
                </button>
            </div>

            <div className="contract-for-hire-table">
                <div>
                    <button className="add-auction-item-button" onClick={() => setItemInLocalStorage([defaultEmptyValue, ...items])}>Add Auction Item</button>
                </div>
                {items.map((item, index) => (
                    <TableRow
                        key={index}
                        auctionItem={cardIndexedByName?.[item?.itemId]}
                        deleteItem={() => {
                            const newItems = cloneDeep(items).filter((_, i) => i !== index);
                            setItemInLocalStorage(newItems);
                        }}
                        index={index}
                        item={item}
                        players={players}
                        setItem={(newItem) => {
                            const newScores = cloneDeep(items).map((item, i) => i === index ? newItem : item);
                            setItemInLocalStorage(newScores);
                        }}
                        selectedAuctionItems={items.map(item => item.itemId).filter(Boolean).map(Number)}
                    />
                ))}
            </div>
        </div>
    )
}

const TableRow = ({ auctionItem, deleteItem, item, players, setItem, selectedAuctionItems }) => {
    const { rewards, winner } = item;
    const defaultEmptyValue = getDefaultEmptyValue(players);

    const getPosition = (player) => {
        const includesPlayer = item.positions.includes(player);
        if (!includesPlayer) return '';
        return item.positions.findIndex(position => position === player) + 1
    }

    useEffect(() => {
        if (auctionItem?.id && Number(auctionItem?.id) !== Number(item.itemId)) {
            setItem({
                ...item,
                winner: '',
                scores: cloneDeep(players).reduce((acc, player) => ({ ...acc, [player]: 0 }), {}),
                rewards: {
                    value: auctionItem.reward,
                    owner: [],
                    others: [],
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auctionItem?.id]);

    const selectReward = (type, value) => {
        const previousWinner = winner;
        let newWinner = winner;
        const newItem = cloneDeep(item);
        const newRewards = cloneDeep(newItem.rewards ?? { value: auctionItem.reward, owner: [], others: [] });

        switch (type) {
            case OWNER:
            case OTHERS:
                const includes = newRewards[type].find(id => Number(id) === Number(value));
                if (includes) {
                    // remove the rewardId from the respective array
                    const newRewardType = newRewards[type].filter(id => Number(id) !== Number(value));
                    newRewards[type] = newRewardType;
                } else {
                    // add the rewardId to the respective array
                    newRewards[type] = [...newRewards[type], Number(value)];
                    // remove the rewardId from the other array
                    const otherType = type === OWNER ? OTHERS : OWNER;
                    const newOtherType = newRewards[otherType].filter(id => Number(id) !== Number(value));
                    newRewards[otherType] = newOtherType;
                }
                break;
            case 'winner':
                newWinner = value;
                break;
            default:
                break;
        }

        // calculate the total reward        
        const owner = newRewards.owner.map(rewardId => {
            const reward = auctionItem.rewardCard.find(card => Number(card.id) === Number(rewardId));
            return reward?.owner;
        });

        const others = newRewards.others.map(rewardId => {
            const reward = auctionItem.rewardCard.find(card => Number(card.id) === Number(rewardId));            
            return reward?.others;
        });

        const totalReward = owner.concat(others).reduce((acc, value) => (acc + value), 0) + newRewards.value;
        const newScores = cloneDeep({ ...newItem.scores });
        if (previousWinner) newScores[previousWinner] = 0;
        if (newItem.winner) newScores[newItem.winner] = totalReward;

        setItem({
            ...item,
            rewards: newRewards,
            scores: newScores,
            winner: newWinner,
        });
    };

    const checkPlayerIsIncluded = (player) => item.positions.includes(player) || item.bids[player] !== '';

    return (
        <div className="table-row-container">
            <div className="table-cell auction-item-name-cell">
                <div className="auction-item-name-container">
                    <strong className="auction-item-heading">Auction Item</strong>
                    <select
                        className="auction-item-name-selector"
                        value={item.itemId}
                        onChange={({ target }) =>
                            setItem({
                                ...item,
                                ...defaultEmptyValue,
                                itemId: target.value,
                            })}
                    >
                        <option value=''>Select Auction Item</option>
                        {cards.map(card => (
                            <option key={card.id} value={card.id} disabled={selectedAuctionItems.includes(card.id)}>{card.name}</option>
                        ))}
                    </select>
                </div>
                <button className="delete-auction-item-button" onClick={deleteItem}>Delete</button>
            </div>

            <div className="table-cell auction-bid-cell">
                <div className="auction-bid-heading-container">
                    <strong className="auction-bid-player-heading">Player</strong>
                    <strong className="auction-bid-order-heading">Order</strong>
                    <strong className="auction-bid-rolls-heading">Rolls</strong>
                    <strong className="auction-bid-cost-heading">Cost</strong>
                </div>
                {players.map((player, playerIndex) => (
                    <div key={playerIndex} className="auction-bid-container">
                        <span className="player-value">{player}</span>
                        {/* ORDER */}
                        {auctionItem && !auctionItem?.costPerDice?.['all'] ? (
                            <select
                                className="order-value"
                                value={getPosition(player)}
                                onChange={({ target }) => {
                                    const value = target.value;

                                    if (value === '') {
                                        // filter out the player from the positions
                                        const newBids = { ...item.bids };
                                        newBids[player] = '';

                                        setItem({
                                            ...item,
                                            bids: newBids,
                                            positions: item.positions.map((position) => {
                                                if (position === player) {
                                                    return '';
                                                }
                                                return position;
                                            }),
                                        });
                                    } else {
                                        // override the position in the positions array with the player
                                        const valueIndex = Number(value) - 1;
                                        const newPositions = [...item.positions].map((position) => {
                                            if (position === player) return '';
                                            return position;
                                        });
                                        newPositions[valueIndex] = player;

                                        // if any player is not in the positions array, set their bid to ''
                                        const newBids = { ...item.bids };
                                        players.forEach((p) => {
                                            if (!newPositions.includes(p)) {
                                                newBids[p] = '';
                                            }
                                        })

                                        setItem({
                                            ...item,
                                            bids: newBids,
                                            positions: newPositions,
                                        });
                                    }
                                }}
                            >
                                <option value=''>N/A</option>
                                {players.map((_, index) => (
                                    <option key={index} value={index + 1}>{index + 1}</option>
                                ))}
                            </select>
                        ) : (
                            <span className="empty-order-value"></span>
                        )}
                        {/* BID */}
                        {auctionItem?.diceBidOptions.length > 1 ? (
                            <select
                                className="bid-value"
                                value={item.bids[player]}
                                onChange={({ target }) => {
                                    const newBids = { ...item.bids };
                                    newBids[player] = target.value;

                                    setItem({
                                        ...item,
                                        bids: newBids,
                                    })
                                }}
                            >
                                <option value=''>N/A</option>
                                {auctionItem?.diceBidOptions.map(option => (
                                    <option key={option} value={option}>{option}</option>
                                ))}
                            </select>
                        ) : (
                            <span className="empty-bid-value"></span>
                        )}
                        {/* COST */}
                        <span className="cost-value">{getBidAmount(player, auctionItem, item)}</span>
                    </div>
                ))}
            </div>

            <div className="table-cell auction-reward-cell">
                <strong className="auction-reward-heading">Reward</strong>
                <ul className="auction-reward-container">
                    <li className="auction-reward-list-item winner-selector-reward-list-item">
                        <span className="auction-reward-list-item-heading">Winner</span>
                        {auctionItem ? (
                            <select
                                className="winner-selector"
                                value={winner}
                                onChange={({ target }) => selectReward('winner', target.value)}>
                                <option value=''>No Winner</option>
                                {players.map((player, index) => (
                                    <option key={index} value={player} disabled={!checkPlayerIsIncluded(player)}>{player}</option>
                                ))}
                            </select>
                        ) : (
                            <span className="winner-selector"></span>
                        )}
                    </li>
                    <li className="auction-reward-list-item">
                        <span className="auction-reward-list-item-heading">Value</span>
                        {auctionItem && (
                            <div className="auction-reward-list-item-option-container">
                                <div className="auction-reward-list-item-option-value default-selected-list-item-card">{auctionItem?.reward}</div>
                            </div>
                        )}
                    </li>
                    {auctionItem?.rewardCard.map((option, index) => (
                        <li key={index} className="auction-reward-list-item reward-list-item-or-card-type">
                            <span className="auction-reward-list-item-heading">Card {index + 1}</span>
                            {auctionItem && (
                                <div className="auction-or-reward-list-item-option-container">
                                    <span className="auction-or-reward-list-item-heading">Own</span>
                                    <div
                                        className={`auction-reward-list-item-option-value ${rewards?.owner?.find(id => Number(id) === Number(option.id)) ? 'selected-list-item-card' : ''}`}
                                        onClick={() => selectReward(OWNER, Number(option.id))}
                                    >
                                        {option.owner}
                                    </div>
                                </div>
                            )}
                            <span className="or-text">OR</span>
                            {auctionItem && (
                                <div className="auction-or-reward-list-item-option-container">
                                    <span className="auction-or-reward-list-item-heading">Others</span>
                                    <div
                                        className={`auction-reward-list-item-option-value ${rewards?.others?.find(id => Number(id) === Number(option.id)) ? 'selected-list-item-card' : ''}`}
                                        onClick={() => selectReward(OTHERS, Number(option.id))}
                                    >
                                        {option.others}
                                    </div>
                                </div>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
};
