import React, { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { useTab } from '../../../components/Tab';
import { LocalStorageKeys, getLocalStorageValue, setLocalStorageValue } from '../../../../utils';
import { tabData } from './data/tab-data';
import { GameDetails } from './GameDetails';
import { Rooms } from './Rooms';
import { ScoreSheet } from './ScoreSheet';

export const ContractForHireScoreSheet = () => {
    const { selectedTab, setSelectedTab, getTabs } = useTab({ tabData });
    const [rooms, setRooms] = useState(getLocalStorageValue(LocalStorageKeys.SILENT_AUCTION_SCORE_SHEET_ROOMS) ?? []);
    const [selectedRoom, setSelectedRoom] = useState(0);

    const setRoom = (roomData) => {
        const roomsCopy = [...cloneDeep(rooms)];
        roomsCopy[selectedRoom] = roomData;
        setRooms(roomsCopy);
        setLocalStorageValue(LocalStorageKeys.SILENT_AUCTION_SCORE_SHEET_ROOMS, roomsCopy);
    }

    useEffect(() => {
        setRooms(getLocalStorageValue(LocalStorageKeys.SILENT_AUCTION_SCORE_SHEET_ROOMS) ?? []);
    }, [selectedTab]);

    return (
        <>
            {getTabs()}
            <div className="contract-for-hire-score-sheet">
                {selectedTab === 'rooms' && (
                    <Rooms {...{ localStorageKey: LocalStorageKeys.SILENT_AUCTION_SCORE_SHEET_ROOMS, rooms, setRooms, selectedRoom, setSelectedRoom }} />
                )}
                {selectedTab === 'details' && (
                    <GameDetails {...{ room: rooms[selectedRoom] || {}, setRoom }} />
                )}
                {selectedTab === 'score' && (
                    <ScoreSheet {...{ room: rooms[selectedRoom] || {}, setRoom, setSelectedTab }} />
                )}
            </div>
        </>
    )
}