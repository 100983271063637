export const cards = [
    {
        "id": 1,
        "name": "Sokka's Boomerang",
        "condition": "First to roll a straight of three with each of the two smaller values (e.g. 1-2-3-2-1).",
        "diceBidOptions": [
            6
        ],
        "reward": 70,
        "rewardCard": [
            { id: 1, "owner": 40, "others": 30 },
            { id: 2, "owner": 35, "others": 25 },
            { id: 3, "owner": 30, "others": 20 },
        ],
        "costPerDice": {
            "1": 60,
            "2": 50,
            "3": 40,
            "4": 30,
            "5": 20,
            "6": 10
        }
    },
    {
        "id": 2,
        "name": "Iron Man Helmet",
        "condition": "First to roll exactly one 3.",
        "diceBidOptions": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "reward": 150,
        "rewardCard": [
            { id: 1, "owner": 25, "others": 15 },
            { id: 2, "owner": 15, "others": 5 },
            { id: 3, "owner": 40, "others": 25 },
        ],
        "costPerDice": {
            "1": 30,
            "2": 25,
            "3": 20,
            "4": 15,
            "5": 10,
            "6": 5
        }
    },
    {
        "id": 3,
        "name": "The One Ring",
        "condition": "Most rolled 1s.",
        "diceBidOptions": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "reward": 100,
        "rewardCard": [
            { id: 1, "owner": 140, "others": 130 },
            { id: 2, "owner": 120, "others": 100 },
            { id: 3, "owner": 100, "others": 90 },
        ],
        "costPerDice": {
            "all": 80
        }
    },
    {
        "id": 4,
        "name": "Beef Wellington Cooked by Gordon Ramsay",
        "condition": "First to roll a minimum of 20.",
        "diceBidOptions": [
            4,
            5,
            6
        ],
        "reward": 50,
        "rewardCard": [
            { id: 1, "owner": 20, "others": 15 },
            { id: 2, "owner": 30, "others": 25 },
            { id: 3, "owner": 25, "others": 20 },
        ],
        "costPerDice": {
            "1": 30,
            "2": 25,
            "3": 20,
            "4": 15,
            "5": 10,
            "6": 5
        }
    },
    {
        "id": 5,
        "name": "All the Bacon and Egg from JJ's Diner",
        "condition": "First to get a 5-dice straight. You can re-roll as many dice as you want.",
        "diceBidOptions": [
            6
        ],
        "reward": 65,
        "rewardCard": [
            { id: 1, "owner": 25, "others": 25 },
            { id: 2, "owner": 20, "others": 15 },
            { id: 3, "owner": 15, "others": 25 },
        ],
        "costPerDice": {
            "1": 60,
            "2": 50,
            "3": 40,
            "4": 30,
            "5": 20,
            "6": 10
        }
    },
    {
        "id": 6,
        "name": "Serena and Venus Williams' Signed Tennis Racket",
        "condition": "First to roll exactly one 2.",
        "diceBidOptions": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "reward": 115,
        "rewardCard": [
            { id: 1, "owner": 55, "others": 40 },
            { id: 2, "owner": 40, "others": 50 },
            { id: 3, "owner": 45, "others": 30 },
        ],
        "costPerDice": {
            "1": 30,
            "2": 25,
            "3": 20,
            "4": 15,
            "5": 10,
            "6": 5
        }
    },
    {
        "id": 7,
        "name": "Elder Wand",
        "condition": "Most rolled 1s.",
        "diceBidOptions": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "reward": 130,
        "rewardCard": [
            { id: 1, "owner": 75, "others": 65 },
            { id: 2, "owner": 95, "others": 75 },
            { id: 3, "owner": 120, "others": 100 },
        ],
        "costPerDice": {
            "all": 100
        }
    },
    {
        "id": 8,
        "name": "Goggles Sponsored by Michael Phelps",
        "condition": "First to roll a minimum of 28.",
        "diceBidOptions": [
            5,
            6
        ],
        "reward": 50,
        "rewardCard": [
            { id: 1, "owner": 30, "others": 25 },
            { id: 2, "owner": 15, "others": 15 },
            { id: 3, "owner": 20, "others": 10 },
        ],
        "costPerDice": {
            "1": 30,
            "2": 25,
            "3": 20,
            "4": 15,
            "5": 10,
            "6": 5
        }
    },
    {
        "id": 9,
        "name": "Leslie Knope's Council Campaign Button",
        "condition": "Most rolled 2s.",
        "diceBidOptions": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "reward": 25,
        "rewardCard": [
            { id: 1, "owner": 10, "others": 5 },
            { id: 2, "owner": 5, "others": 10 },
            { id: 3, "owner": 15, "others": 10 },
        ],
        "costPerDice": {
            "all": 5
        }
    },
    {
        "id": 10,
        "name": "3-Pointer Shooting Masters Class by Steph Curry",
        "condition": "Most rolled 3s.",
        "diceBidOptions": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "reward": 60,
        "rewardCard": [
            { id: 1, "owner": 50, "others": 40 },
            { id: 2, "owner": 60, "others": 50 },
            { id: 3, "owner": 75, "others": 30 },
        ],
        "costPerDice": {
            "all": 20
        }
    },
    {
        "id": 11,
        "name": "Ruby Slippers",
        "condition": "Most rolled 3s.",
        "diceBidOptions": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "reward": 100,
        "rewardCard": [
            { id: 1, "owner": 100, "others": 80 },
            { id: 2, "owner": 120, "others": 100 },
            { id: 3, "owner": 150, "others": 120 },
        ],
        "costPerDice": {
            "all": 50
        }
    },
    {
        "id": 12,
        "name": "Yo-Yo Ma's Cello",
        "condition": "Most rolled 4s.",
        "diceBidOptions": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "reward": 80,
        "rewardCard": [
            { id: 1, "owner": 120, "others": 100 },
            { id: 2, "owner": 70, "others": 60 },
            { id: 3, "owner": 85, "others": 80 },
        ],
        "costPerDice": {
            "all": 15
        }
    },
    {
        "id": 13,
        "name": "Batman's Tumbler",
        "condition": "Most rolled 5s.",
        "diceBidOptions": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "reward": 190,
        "rewardCard": [
            { id: 1, "owner": 230, "others": 220 },
            { id: 2, "owner": 200, "others": 175 },
            { id: 3, "owner": 180, "others": 200 },
        ],
        "costPerDice": {
            "all": 40
        }
    },
    {
        "id": 14,
        "name": "Darth Vader Helmet",
        "condition": "Most rolled 6s.",
        "diceBidOptions": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "reward": 150,
        "rewardCard": [
            { id: 1, "owner": 140, "others": 180 },
            { id: 2, "owner": 190, "others": 160 },
            { id: 3, "owner": 200, "others": 200 },
        ],
        "costPerDice": {
            "all": 25
        }
    },
    {
        "id": 15,
        "name": "Infinity Gauntlet",
        "condition": "Most rolled 6s.",
        "diceBidOptions": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "reward": 180,
        "rewardCard": [
            { id: 1, "owner": 180, "others": 220 },
            { id: 2, "owner": 215, "others": 200 },
            { id: 3, "owner": 165, "others": 180 },
        ],
        "costPerDice": {
            "all": 60
        }
    },
    {
        "id": 16,
        "name": "Babe Ruth's \"Called Shot\" Jersey",
        "condition": "First to roll one 3 and one 2.",
        "diceBidOptions": [
            2,
            3,
            4,
            5,
            6
        ],
        "reward": 100,
        "rewardCard": [
            { id: 1, "owner": 100, "others": 80 },
            { id: 2, "owner": 120, "others": 100 },
            { id: 3, "owner": 140, "others": 80 },
        ],
        "costPerDice": {
            "all": 35
        }
    },
    {
        "id": 17,
        "name": "Captain America's Shield",
        "condition": "Most rolled 4s and 1s collectively.",
        "diceBidOptions": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "reward": 125,
        "rewardCard": [
            { id: 1, "owner": 120, "others": 100 },
            { id: 2, "owner": 150, "others": 130 },
            { id: 3, "owner": 160, "others": 110 },
        ],
        "costPerDice": {
            "all": 45
        }
    },
    {
        "id": 18,
        "name": "Necklace from Titanic",
        "condition": "Most rolled 4s and 1s collectively.",
        "diceBidOptions": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "reward": 90,
        "rewardCard": [
            { id: 1, "owner": 75, "others": 60 },
            { id: 2, "owner": 60, "others": 70 },
            { id: 3, "owner": 65, "others": 85 },
        ],
        "costPerDice": {
            "all": 25
        }
    },
    {
        "id": 19,
        "name": "Aragorn's Sword",
        "condition": "Most rolled 6s.",
        "diceBidOptions": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "reward": 130,
        "rewardCard": [
            { id: 1, "owner": 150, "others": 140 },
            { id: 2, "owner": 130, "others": 160 },
            { id: 3, "owner": 140, "others": 130 },
        ],
        "costPerDice": {
            "all": 25
        }
    },
    {
        "id": 20,
        "name": "Wilson from Cast Away",
        "condition": "Most rolled 1s and 5s collectively.",
        "diceBidOptions": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "reward": 30,
        "rewardCard": [
            { id: 1, "owner": 35, "others": 25 },
            { id: 2, "owner": 25, "others": 20 },
            { id: 3, "owner": 30, "others": 15 },
        ],
        "costPerDice": {
            "all": 10
        }
    },
    // {
    //     "id": 21,
    // "name": "Jimmer Fredette BYU Jersey",
    //     "condition": "Most rolled 3s and 2s collectively.",
    //     "diceBidOptions": [
    //         1,
    //         2,
    //         3,
    //         4,
    //         5,
    //         6
    //     ],
    //     "reward": 0,
    //     "rewardCard": [
    //         { id: 1, "owner": 0, "others": 0 },
    //         { id: 2, "owner": 0, "others": 0 },
    //         { id: 3, "owner": 0, "others": 0 },
    //     ],
    //     "costPerDice": {
    //         "all": 25
    //     }
    // },
    // {
    //     "id": 22,
    //     "name": "\"The World's Best Boss\" Mug",
    //     "condition": "Most rolled 6s and 2s collectively.",
    //     "diceBidOptions": [
    //         1,
    //         2,
    //         3,
    //         4,
    //         5,
    //         6
    //     ],
    //     "reward": 0,
    //     "rewardCard": [
    //         { id: 1, "owner": 0, "others": 0 },
    //         { id: 2, "owner": 0, "others": 0 },
    //         { id: 3, "owner": 0, "others": 0 },
    //     ],
    //     "costPerDice": {
    //         "all": 5
    //     }
    // },
    // {
    //     "id": 23,
    //     "name": "Deathly Hallows Necklace",
    //     "condition": "First to roll three 3s. You can re-roll as many dice as you want once.",
    //     "diceBidOptions": [
    //         3,
    //         4,
    //         5,
    //         6
    //     ],
    //     "reward": 0,
    //     "rewardCard": [
    //         { id: 1, "owner": 0, "others": 0 },
    //         { id: 2, "owner": 0, "others": 0 },
    //         { id: 3, "owner": 0, "others": 0 },
    //     ],
    //     "costPerDice": {
    //         "all": 15
    //     }
    // },
    // {
    //     "id": 24,
    //     "name": "Bird Lady's Turtle Dove Ornaments",
    //     "condition": "First to roll two 2s",
    //     "diceBidOptions": [
    //         2,
    //         3,
    //         4,
    //         5,
    //         6
    //     ],
    //     "reward": 0,
    //     "rewardCard": [
    //         { id: 1, "owner": 0, "others": 0 },
    //         { id: 2, "owner": 0, "others": 0 },
    //         { id: 3, "owner": 0, "others": 0 },
    //     ],
    //     "costPerDice": {
    //         "all": 5
    //     }
    // },
    // {
    //     "id": 25,
    //     "name": "Charles Lindbergh's Spirit of St. Louis Airplane",
    //     "condition": "First to roll two 3s.",
    //     "diceBidOptions": [
    //         2,
    //         3,
    //         4,
    //         5,
    //         6
    //     ],
    //     "reward": 0,
    //     "rewardCard": [
    //         { id: 1, "owner": 0, "others": 0 },
    //         { id: 2, "owner": 0, "others": 0 },
    //         { id: 3, "owner": 0, "others": 0 },
    //     ],
    //     "costPerDice": {
    //         "all": 35
    //     }
    // },
    // {
    //     "id": 26,
    //     "name": "Lightsaber",
    //     "condition": "First to roll exactly 7. If no player's rolled number, first player closest to the number wins.",
    //     "diceBidOptions": [
    //         2,
    //         3,
    //         4
    //     ],
    //     "reward": 0,
    //     "rewardCard": [
    //         { id: 1, "owner": 0, "others": 0 },
    //         { id: 2, "owner": 0, "others": 0 },
    //         { id: 3, "owner": 0, "others": 0 },
    //     ],
    //     "costPerDice": {
    //         "1": 30,
    //         "2": 25,
    //         "3": 20,
    //         "4": 15,
    //         "5": 10,
    //         "6": 5
    //     }
    // },
    // {
    //     "id": 27,
    //     "name": "Game Winning Soccer Ball Signed by Cristiano Ronaldo",
    //     "condition": "First to roll exactly 7. If no player's rolled number, first player closest to the number wins.",
    //     "diceBidOptions": [
    //         2,
    //         3,
    //         4
    //     ],
    //     "reward": 0,
    //     "rewardCard": [
    //         { id: 1, "owner": 0, "others": 0 },
    //         { id: 2, "owner": 0, "others": 0 },
    //         { id: 3, "owner": 0, "others": 0 },
    //     ],
    //     "costPerDice": {
    //         "1": 30,
    //         "2": 25,
    //         "3": 20,
    //         "4": 15,
    //         "5": 10,
    //         "6": 5
    //     }
    // },
    // {
    //     "id": 28,
    //     "name": "Michael Jackson's Jacket and Hat",
    //     "condition": "First to roll exactly 7. If no player's rolled number, first player closest to the number wins.",
    //     "diceBidOptions": [
    //         2,
    //         3,
    //         4
    //     ],
    //     "reward": 0,
    //     "rewardCard": [
    //         { id: 1, "owner": 0, "others": 0 },
    //         { id: 2, "owner": 0, "others": 0 },
    //         { id: 3, "owner": 0, "others": 0 },
    //     ],
    //     "costPerDice": {
    //         "1": 30,
    //         "2": 25,
    //         "3": 20,
    //         "4": 15,
    //         "5": 10,
    //         "6": 5
    //     }
    // },
    {
        "id": 29,
        "name": "Mjolnir, Thor's Hammer",
        "condition": "First to roll exactly 9. If no player's rolled number, first player closest to the number wins.",
        "diceBidOptions": [
            2,
            3,
            4
        ],
        "reward": 110,
        "rewardCard": [
            { id: 1, "owner": 130, "others": 140 },
            { id: 2, "owner": 160, "others": 120 },
            { id: 3, "owner": 140, "others": 140 },
        ],
        "costPerDice": {
            "1": 30,
            "2": 25,
            "3": 20,
            "4": 15,
            "5": 10,
            "6": 5
        }
    },
    {
        "id": 30,
        "name": "Delorean from Back to the Future",
        "condition": "First to roll exactly 12. If no player's rolled number, first player closest to the number wins.",
        "diceBidOptions": [
            2,
            3,
            4,
            5
        ],
        "reward": 180,
        "rewardCard": [
            { id: 1, "owner": 110, "others": 170 },
            { id: 2, "owner": 140, "others": 120 },
            { id: 3, "owner": 160, "others": 130 },
        ],
        "costPerDice": {
            "1": 40,
            "2": 35,
            "3": 30,
            "4": 25,
            "5": 20,
            "6": 15
        }
    },
    {
        "id": 31,
        "name": "Taylor Swift Signed Guitar",
        "condition": "First to roll exactly 13. If no player's rolled number, first player closest to the number wins.",
        "diceBidOptions": [
            3,
            4,
            5,
            6
        ],
        "reward": 320,
        "rewardCard": [
            { id: 1, "owner": 200, "others": 150 },
            { id: 2, "owner": 180, "others": 150 },
            { id: 3, "owner": 130, "others": 170 },
        ],
        "costPerDice": {
            "1": 60,
            "2": 50,
            "3": 40,
            "4": 30,
            "5": 20,
            "6": 10
        }
    },
    {
        "id": 32,
        "name": "Etch and sketch of the Mona Lisa",
        "condition": "First to roll exactly 13. If no player's rolled number, first player closest to the number wins.",
        "diceBidOptions": [
            3,
            4,
            5,
            6
        ],
        "reward": 170,
        "rewardCard": [
            { id: 1, "owner": 100, "others": 130 },
            { id: 2, "owner": 90, "others": 120 },
            { id: 3, "owner": 130, "others": 90 },
        ],
        "costPerDice": {
            "1": 30,
            "2": 25,
            "3": 20,
            "4": 15,
            "5": 10,
            "6": 5
        }
    },
    {
        "id": 33,
        "name": "Joey Tribbiani's Hugsy Penguin",
        "condition": "First to roll exactly 19. If no player's rolled number, first player closest to the number wins.",
        "diceBidOptions": [
            4,
            5,
            6
        ],
        "reward": 380,
        "rewardCard": [
            { id: 1, "owner": 150, "others": 120 },
            { id: 2, "owner": 120, "others": 80 },
            { id: 3, "owner": 100, "others": 90 },
        ],
        "costPerDice": {
            "1": 60,
            "2": 55,
            "3": 50,
            "4": 45,
            "5": 40,
            "6": 35
        }
    },
    {
        "id": 34,
        "name": "Indiana Jone's Bullwhip",
        "condition": "First to roll exactly 18. If no player's rolled number, first player closest to the number wins.",
        "diceBidOptions": [
            3,
            4,
            5,
            6
        ],
        "reward": 170,
        "rewardCard": [
            { id: 1, "owner": 90, "others": 110 },
            { id: 2, "owner": 120, "others": 160 },
            { id: 3, "owner": 70, "others": 80 },
        ],
        "costPerDice": {
            "1": 30,
            "2": 25,
            "3": 20,
            "4": 15,
            "5": 10,
            "6": 5
        }
    },
    {
        "id": 35,
        "name": "Belle's Ballroom Gown",
        "condition": "First to roll exactly 17. If no player's rolled number, first player closest to the number wins.",
        "diceBidOptions": [
            3,
            4,
            5,
            6
        ],
        "reward": 320,
        "rewardCard": [
            { id: 1, "owner": 180, "others": 120 },
            { id: 2, "owner": 130, "others": 160 },
            { id: 3, "owner": 100, "others": 200 },
        ],
        "costPerDice": {
            "1": 60,
            "2": 55,
            "3": 50,
            "4": 45,
            "5": 40,
            "6": 35
        }
    },
    {
        "id": 36,
        "name": "Kevin McCallister's Battle Plan",
        "condition": "First to roll exactly 14. If no player's rolled number, first player closest to the number wins.",
        "diceBidOptions": [
            3,
            4,
            5
        ],
        "reward": 170,
        "rewardCard": [
            { id: 1, "owner": 60, "others": 90 },
            { id: 2, "owner": 80, "others": 120 },
            { id: 3, "owner": 100, "others": 100 },
        ],
        "costPerDice": {
            "1": 30,
            "2": 25,
            "3": 20,
            "4": 15,
            "5": 10,
            "6": 5
        }
    },
    {
        "id": 37,
        "name": "Monica and Rachel's Peephole Door Frame",
        "condition": "First to roll exactly 20. If no player's rolled number, first player closest to the number wins.",
        "diceBidOptions": [
            4,
            5,
            6
        ],
        "reward": 380,
        "rewardCard": [
            { id: 1, "owner": 140, "others": 160 },
            { id: 2, "owner": 125, "others": 100 },
            { id: 3, "owner": 175, "others": 130 },
        ],
        "costPerDice": {
            "1": 60,
            "2": 55,
            "3": 50,
            "4": 45,
            "5": 40,
            "6": 35
        }
    },
    {
        "id": 38,
        "name": "Air Jordan Sneakers Signed by Michael Jordan",
        "condition": "First to roll exactly 23. If no player's rolled number, first player closest to the number wins.",
        "diceBidOptions": [
            4,
            5,
            6
        ],
        "reward": 260,
        "rewardCard": [
            { id: 1, "owner": 190, "others": 130 },
            { id: 2, "owner": 140, "others": 150 },
            { id: 3, "owner": 160, "others": 120 },
        ],
        "costPerDice": {
            "1": 40,
            "2": 35,
            "3": 30,
            "4": 25,
            "5": 20,
            "6": 15
        }
    },
    {
        "id": 39,
        "name": "Rugrat's Reptar Toy",
        "condition": "First to roll exactly 24. If no player's rolled number, first player closest to the number wins.",
        "diceBidOptions": [
            4,
            5,
            6
        ],
        "reward": 200,
        "rewardCard": [
            { id: 1, "owner": 50, "others": 80 },
            { id: 2, "owner": 150, "others": 65 },
            { id: 3, "owner": 75, "others": 160 },
        ],
        "costPerDice": {
            "1": 30,
            "2": 25,
            "3": 20,
            "4": 15,
            "5": 10,
            "6": 5
        }
    },
    {
        "id": 40,
        "name": "Pokeball",
        "condition": "First to roll exactly 25. If no player's rolled number, first player closest to the number wins.",
        "diceBidOptions": [
            5,
            6
        ],
        "reward": 290,
        "rewardCard": [
            { id: 1, "owner": 250, "others": 140 },
            { id: 2, "owner": 110, "others": 180 },
            { id: 3, "owner": 160, "others": 140 },
        ],
        "costPerDice": {
            "1": 45,
            "2": 40,
            "3": 35,
            "4": 30,
            "5": 25,
            "6": 20
        }
    },
    // {
    //     "id": 41,
    //     "name": "Storm Trooper Suit",
    //     "condition": "Most set of doubles.",
    //     "diceBidOptions": [
    //         2,
    //         3,
    //         4,
    //         5,
    //         6
    //     ],
    //     "reward": 0,
    //     "rewardCard": [
    //         { id: 1, "owner": 0, "others": 0 },
    //         { id: 2, "owner": 0, "others": 0 },
    //         { id: 3, "owner": 0, "others": 0 },
    //     ],
    //     "costPerDice": {
    //         "all": 30
    //     }
    // },
    // {
    //     "id": 42,
    //     "name": "",
    //     "condition": "",
    //     "diceBidOptions": [],
    //     "reward": 0,
    //     "rewardCard": [
    //         { id: 1, "owner": 0, "others": 0 },
    //         { id: 2, "owner": 0, "others": 0 },
    //         { id: 3, "owner": 0, "others": 0 },
    //     ],
    //     "costPerDice": {
    //         "1": 60,
    //         "2": 50,
    //         "3": 40,
    //         "4": 30,
    //         "5": 20,
    //         "6": 10
    //     }
    // },
]