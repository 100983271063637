export const LocalStorageKeys = {
    CART: 'cart',
    GENERIC_SCORE_SHEET_ROOMS: 'generic-score-sheet-rooms',
    SILENT_AUCTION_SCORE_SHEET_ROOMS: 'contract-for-hire-score-sheet-rooms',
}

export const getLocalStorageValue = (key, defaultValue) => {
    const value = localStorage.getItem(key);
    if (!value || value === 'undefined') {
        localStorage.setItem(key, defaultValue);
        return defaultValue;
    }
    return JSON.parse(value);
}

export const setLocalStorageValue = (key, value) => {
    const formattedValue = JSON.stringify(value);
    localStorage.setItem(key, formattedValue);
}

