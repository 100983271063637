import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useLoader } from '../../hooks/use-loader';
import { Breadcrumb, FeedbackForm, Footer, Loader } from '../components';
import { ContractForHireScoreSheet, GenericScoreSheet } from './resources';

export const TypeResourceDetail = ({ checkHeight, match }) => {
    const id = match.params.id;
    const type = match.params.type;
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setIsReady(true);
        }, 50);
    }, [])

    const isLoading = useLoader(isReady);

    const displayResource = () => {
        if (id === 'basic-score-sheet') return <GenericScoreSheet />;
        if (id === 'contract-for-hire-score-sheet') return <ContractForHireScoreSheet />;
        return <Redirect to={`/products/${type}`} />;
    }

    if (isLoading) return <Loader />;

    return (
        <div id="type-resource-detail-page" className="type-resource-detail page" onScroll={() => checkHeight('.breadcrumbs')}>
            <div className="container">
                <Breadcrumb type="games" />
                <div className="resource-container">
                    {displayResource()}
                </div>
            </div>
            <FeedbackForm product={'resource-' + id} type="resource" />
            <Footer />
        </div>
    );
}